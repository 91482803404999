<template>
  <div class="signup-card" v-if="!isProcessing">
    <signup-card-form @set-card-name="setPostParams" @set-card="setCardInfo" :is-valid-card-name=isValidCardName />
    <v-btn class="signup-card__btn" depressed :disabled="!isFilled" @click="registrateCard()">利用を開始する</v-btn>
    <warning class="signup-card__warning" />
  </div>
</template>

<script>
import SignupCardForm from '@/components/common/form/card.vue'
import Warning from '@/components/signup/warning.vue'

import { stripe } from '@/stripe'

export default {
  name: 'signup-card',
  components: { SignupCardForm, Warning },
  data () {
    return {
      postParams: {
        // カード名義
        cardName: '',
        // カード番号
        cardNumber: '',
        // カード期限
        cardExpiry: '',
        // カードCVC
        cardCvc: ''
      },
      // 正しいカード情報かどうか
      isCorrect: {
        // 番号
        cardNumber: '',
        // 期限
        cardExpiry: '',
        // CVC
        cardCvc: ''
      }
    }
  },
  async mounted () {
    // 限定グループ名を取得していない場合、取得する
    if (!this.groupName) await this.$store.dispatch('settingGroup/getGroupSetting', 'groupName')

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Boolean} 限定グループ設定の状態
     */
    isSetGroupSetting () {
      return this.$store.getters['settingGroup/isSetGroupSetting']
    },
    /**
     * @return {String} 限定グループ名
     */
    groupName () {
      return this.$store.getters['settingGroup/groupName']
    },
    /**
     * @return {Boolean} カード番号,期限,CVCが入力されているかどうか
     */
    isCorrectCard () {
      // 入力必須項目
      const requiredKey = ['cardNumber', 'cardExpiry', 'cardCvc']
      return requiredKey.every(key => this.isCorrect[key])
    },
    /**
     * @return {Boolean} カード名義が半角ローマ字と半角スペースのみかどうか
     */
    isValidCardName () {
      const regex = /[a-zA-Z ]/g
      const cardName = this.postParams.cardName.match(regex)
      return cardName ? this.postParams.cardName.trim().length !== 0 && cardName.length === this.postParams.cardName.length : false
    },
    /**
     * @return {Boolean} カード名義とカード情報が入力されているかどうか
     */
    isFilled () {
      return this.isValidCardName && this.isCorrectCard
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters['isProcessing']
    }
  },
  methods: {
    /**
     * ポストパラメータを取得
     * @param {String} key 設定するポストパラメータのキー値
     * @param {String} value 設定するポストパラメータのバリュー値
     */
    setPostParams (key, value) {
      this.postParams[key] = value
    },
    /**
     * 入力されたカード情報を取得
     * @param {String} key 設定するポストパラメータのキー値
     * @param {Object} cardInfo dataに設定するカード情報のオブジェクト
     * @param {Boolean} isCorrectCard 有効なカード情報が入力されているかどうか
     */
    setCardInfo (key, cardInfo, isCorrectCard) {
      this.postParams[key] = cardInfo
      this.isCorrect[key] = isCorrectCard
    },
    /**
     * カードの登録
     */
    async registrateCard () {
      this.$store.commit('setSubmitting', true)

      // 登録失敗時のリスナーが残っている可能性があるため,カード情報監視のリスナーをデタッチする
      this.$store.dispatch('cards/unsubscribeOnNewCard')

      // トークン情報の取得
      const result = await stripe.createToken(this.postParams.cardNumber, { name: this.postParams.cardName })

      // エラーの有無による処理
      if (result.error) {
        this.errorMsg = 'カード情報に誤りがあります'
        this.$store.commit('setSubmitting', false)
      } else {
        // 支払い情報のステータス更新およびトークンの作成
        // 作成したtokenをトリガーにCloud Functionsが呼ばれカード情報を作成する
        await Promise.all([
          this.$store.dispatch('payments/updatePayment', { uid: this.uid, params: { status: 'updatingCard', updatedAt: new Date() } }),
          this.$store.dispatch('tokens/createToken', { uid: this.uid, tokenID: result.token.id })
        ])

        // カード情報の監視
        // Cloud Functions側でcardsが作成されるのを待つ
        await this.$store.dispatch('cards/onNewCard', this.uid)

        // カード情報監視のリスナーをデタッチ
        this.$store.dispatch('cards/unsubscribeOnNewCard')

        // プラン登録のために支払い情報の更新、プラン情報の作成
        await Promise.all([
          this.$store.dispatch('payments/updatePayment', { uid: this.uid, params: { status: 'updatingPlan', updatedAt: new Date() } }),
          this.$store.dispatch('plans/createPlan', { uid: this.uid, planID: process.env.VUE_APP_STRIPE_PLAN_ID })
        ])

        // サインアップ処理完了後限定グループがある場合は限定グループ申請に飛びなければ完了画面に遷移する
        if (this.isSetGroupSetting) {
          this.$router.push({ name: 'signup_group' })
        } else {
          this.$router.push({ name: 'signup_done' })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

.signup-card {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width 0;
  margin: $header_height auto 0;
  &__btn {
    display: block;
    margin: 60px auto 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: $white_color;
    border-radius: 15px;
    &.v-btn:not(.v-btn--round).v-size--default {
      width: auto;
      height: auto;
      padding: 15px 20px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $concept_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      color: $white_color !important;
      background-color: $concept_transparent_color !important;
    }
  }
  &__warning {
    margin-top: 40px;
  }
}
</style>
